<template>
  <div
    v-if="storyBanner && storyBanner.data"
    id="pre_footer_container"
  >
    <StoryBanner
      :heading="storyBanner.data.banner.heading"
      :heading2="storyBanner.data.banner.heading_2"
      :content="storyBanner.data.banner.content"
      :image1="storyBanner.data.banner.image_1"
      :image2="storyBanner.data.banner.image_2"
      :bgcolor="storyBanner.data.banner.background_color"
      :link="storyBanner.data.banner.link"
      :button="storyBanner.data.banner.button"
    />
  </div>
</template>

<script>
import { ref, defineComponent, useFetch } from '@nuxtjs/composition-api';
import { contentGetters, useContent } from '@vue-storefront/gemini';
import { useI18n } from '../helpers/hooks/usei18n';

const StoryBanner = () => import('~/components/StoryTop.vue');

export default defineComponent({
  name: 'PreFooterHome',
  components: {
    StoryBanner,
  },
  setup() {
    const { locale } = useI18n();
    const identifierHomePreFooter = `HomePreFooter-${locale}`;
    const storyBanner = ref({});
    const {
      blocks,
      // error,
      loadBlocks,
    } = useContent(`cmsBlockPreFooterHome${identifierHomePreFooter}`);

    useFetch(async () => {
      await loadBlocks({ identifiers: [identifierHomePreFooter] });
      storyBanner.value = contentGetters.getCmsBlockContent(blocks.value)[identifierHomePreFooter];
    });

    return {
      storyBanner,
    };
  },
});

</script>
